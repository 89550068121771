<template>
  <div class="group-manager-container">
    <div class="manager-icon">
      <svg-icon class="icon-police" icon-class="icon-police"></svg-icon>
      <p><strong>小组管理员</strong></p>
    </div>
    <div class="manager-desc">
      <p>小组管理员可以：</p>
      <p>1、修改小组头像、名称、简介</p>
      <p>2、隐藏小组内容</p>
      <p>3、移出小组成员（不含组长和其它管理员）</p>
    </div>
    <div class="manager-members-container">
      <template v-if="admins.length > 0 && !requesting">
        <div class="member__item" v-for="admin in admins" :key="admin.id">
          <van-swipe-cell
            stop-propagation
            :before-close="onSwipeCellBeforeClose"
            :data-uid="admin.user.uid"
          >
            <div class="item__info">
              <div class="info__headpic">
                <img :src="admin.user.headimgurl" alt="" />
              </div>
              <div class="info__name">{{ admin.user.nickname | comment(admin.user.uid) }}</div>
            </div>
            <template #right>
              <p class="item__remove-bt red swipe-cell-button">删除</p>
            </template>
          </van-swipe-cell>
        </div>
      </template>
      <div v-else>
        <p>{{ requesting ? "请求中" : "" }}</p>
      </div>
      <router-link
        class="member__item member-add-bt"
        v-if="admins.length < 3"
        tag="div"
        :to="{
          path: `/users/search?groupid=${groupid}&leaderuid=${authorUid}&type=admins`
        }"
      >
        <div class="item__info">
          <svg-icon class="bt__icon" icon-class="icon-upload-plus"></svg-icon>
        </div>
      </router-link>
    </div>
    <div class="manager-bottom-container" @click="goBack">
      <p><strong>返回（最多3个）</strong></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-group-manager',
  data() {
    return {
      admins: [],
      requesting: false,
    };
  },
  computed: {
    groupid() {
      return this.$route.params.groupid;
    },
    authorUid() {
      return this.$route.query.author;
    },
  },
  created() {
    this.getAdmins();
  },
  mounted() {},
  methods: {
    getAdmins() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        this.$request
          .get(`api/group/${this.groupid}/member-role/admins`)
          .then((res) => {
            that.admins = res.data;
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onSwipeCellBeforeClose({ position, instance }) {
      const that = this;
      const { uid } = instance.$el.dataset;
      switch (position) {
        case 'left':
        case 'cell':
        case 'outside':
        default:
          instance.close();
          break;
        case 'right': {
          const { admins } = that;
          that.$dialog
            .confirm({
              message: '确定移除该管理员吗？',
              beforeClose: (action, done) => {
                if (action === 'confirm') {
                  that.removeAdmin(uid).then((res) => {
                    if (res.data === 'SUCCESS') {
                      that.admins = admins.filter((item) => Number(item.uid) !== Number(uid));
                    }
                    done();
                  });
                } else {
                  done();
                }
              },
            })
            .then(() => {
              instance.close();
            });
          break;
        }
      }
    },
    removeAdmin(uid) {
      return this.$request.get(`api/group/${this.groupid}/member-role/remove-admin/${uid}`);
    },
  },
};
</script>

<style></style>
